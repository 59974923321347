import Vue from 'vue'
import VueRouter from 'vue-router'
import { authGuard, authAdminGuard } from '@/router/authGuard'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'dashboard',
    beforeEnter: authGuard,
    component: () => import('../views/Dashboard/HomeView.vue'),
  },
  {
    path: '/gateway/',
    name: 'gateway',
    beforeEnter: authGuard,
    component: () => import('../views/Dashboard/GatewayView.vue'),
  },
  {
    path: '/gateway/:gatewayId/sensor/',
    name: 'sensor',
    beforeEnter: authGuard,
    component: () => import('../views/Dashboard/SensorView.vue'),
  },
  {
    path: '/analysis',
    name: 'analysis',
    beforeEnter: authGuard,
    component: () => import('../views/Analysis/HomeView.vue'),
  },
  {
    path: '/report',
    name: 'report',
    component: () => import('../views/Report/HomeView.vue'),
  },
  {
    path: '/alarm',
    name: 'alarm',
    beforeEnter: authGuard,
    component: () => import('../views/Alarm/HomeView.vue'),
  },
  {
    path: '/realtime-monitor',
    name: 'realtimeMonitor',
    beforeEnter: authGuard,
    component: () => import('../views/RealtimeMonitor/HomeView.vue'),
  },
  {
    path: '/data-download',
    name: 'dataDownload',
    beforeEnter: authGuard,
    component: () => import('../views/Download/HomeView.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Auth/LoginView.vue'),
  },
  {
    path: '/user-list',
    name: 'userList',
    beforeEnter: authAdminGuard,
    component: () => import('../views/User/UserListView.vue'),
  },
  {
    path: '/security-policy',
    name: 'securityPolicy',
    beforeEnter: authAdminGuard,
    component: () => import('../views/Admin/SecurityPolicyView.vue'),
  },
  {
    path: '/contract-info',
    name: 'contractInfo',
    beforeEnter: authAdminGuard,
    component: () => import('../views/Admin/ContractInfoView.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
