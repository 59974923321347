import { getLocalStorage, setLocalStorage} from '@/store/localstorage'

const getViewSettings = () => getLocalStorage('viewSettings')

const initViewSettingsIfNeeded = () => {
  let viewSettings = getViewSettings()
  if (!viewSettings) {
    setLocalStorage('viewSettings', {
      dashboardView: {},
      analysisView: {},
      alarmView: {},
      reportView: {},
      dataDownloadView: {},
      realtimeMonitorView: {}
    })
    viewSettings = getViewSettings()
  }
  return viewSettings
}


const defaultVale = (viewSettings, key) => viewSettings && viewSettings[key] ? viewSettings[key] : {}
const initialState = () => {
  const viewSettings = initViewSettingsIfNeeded()
  return {
    gatewayId: undefined,
    sensorId: undefined,
    dashboardView: defaultVale(viewSettings, 'dashboardView'),
    analysisView: defaultVale(viewSettings, 'analysisView'),
    alarmView: defaultVale(viewSettings, 'alarmView'),
    reportView: defaultVale(viewSettings, 'reportView'),
    dataDownloadView: defaultVale(viewSettings, 'dataDownloadView'),
    realtimeMonitorView: defaultVale(viewSettings, 'realtimeMonitorView'),
  }
}
const viewStore = {
  namespaced: true,
  state: initialState,
  getters: {
    gatewayId: (state) => state.gatewayId,
    sensorId: (state) => state.sensorId,
    dashboardView: (state) => state.dashboardView,
    analysisView: (state) => state.analysisView,
    alarmView: (state) => state.alarmView,
    reportView: (state) => state.reportView,
    dataDownloadView: (state) => state.dataDownloadView,
    realtimeMonitorView: (state) => state.realtimeMonitorView
  },
  mutations: {
    setGatewayId(state, value) {
      state.gatewayId = value
    },
    setSensorId(state, value) {
      state.sensorId = value
    },
    setDashboardView(state, value) {
      const viewSettings = getViewSettings()
      const dashboardView = viewSettings.dashboardView
      const newDashboardView = {
        ...dashboardView,
        ...value
      }
      state.dashboardView = newDashboardView

      const newViewSettings = {
        ...viewSettings,
        dashboardView: dashboardView
      }
      setLocalStorage('viewSettings', newViewSettings)
    },
    setAnalysisView(state, value) {
      const viewSettings = getViewSettings()
      const analysisView = viewSettings.analysisView
      const newAnalysisView = {
        ...analysisView,
        ...value
      }
      state.analysisView = newAnalysisView

      const newViewSettings = {
        ...viewSettings,
        analysisView: newAnalysisView
      }
      setLocalStorage('viewSettings', newViewSettings)
    },
    setAlarmView(state, value) {
      const viewSettings = getViewSettings()
      const alarmView = viewSettings.alarmView
      const newAlarmView = {
        ...alarmView,
        ...value
      }
      state.alarmView = newAlarmView

      const newViewSettings = {
        ...viewSettings,
        alarmView: newAlarmView
      }
      setLocalStorage('viewSettings', newViewSettings)
    },
    setReportView(state, value) {
      const viewSettings = getViewSettings()
      const reportView = viewSettings.reportView
      const newReportView = {
        ...reportView,
        ...value
      }
      state.reportView = newReportView

      const newViewSettings = {
        ...viewSettings,
        reportView: newReportView
      }
      setLocalStorage('viewSettings', newViewSettings)
    },
    setDataDownloadView(state, value) {
      const viewSettings = getViewSettings()
      const dataDownloadView = viewSettings.dataDownloadView
      const newDataDownloadView = {
        ...dataDownloadView,
        ...value
      }
      state.dataDownloadView = newDataDownloadView

      const newViewSettings = {
        ...viewSettings,
        dataDownloadView: newDataDownloadView
      }
      setLocalStorage('viewSettings', newViewSettings)
    },
    setRealtimeMonitorView(state, value) {
      const viewSettings = getViewSettings()
      const realtimeMonitorView = viewSettings.realtimeMonitorView
      const newRealtimeMonitorView = {
        ...realtimeMonitorView,
        ...value
      }
      state.realtimeMonitorView = newRealtimeMonitorView

      const newViewSettings = {
        ...viewSettings,
        realtimeMonitorView: newRealtimeMonitorView
      }
      setLocalStorage('viewSettings', newViewSettings)
    },
    reset(state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    }
  },
  actions: {
    saveGatewayId({ commit }, value) {
      commit('setGatewayId', value)
    },
    saveSensorId({ commit }, value) {
      commit('setSensorId', value)
    },
    saveDashboardView({ commit }, value) {
      commit('setDashboardView', value)
    },
    saveAnalysisView({ commit }, value) {
      commit('setAnalysisView', value)
    },
    saveAlarmView({ commit }, value) {
      commit('setAlarmView', value)
    },
    saveReportView({ commit }, value) {
      commit('setReportView', value)
    },
    saveDataDownloadView({ commit }, value) {
      commit('setDataDownloadView', value)
    },
    saveRealtimeMonitorView({ commit }, value) {
      commit('setRealtimeMonitorView', value)
    },
  }
}

export default viewStore
